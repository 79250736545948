<template>
  <ul class="menu-nav">
    <template v-for="(item, index) in menuItems">
      <template v-if="item.header">
        <li class="menu-section" :key="index">
          <h4 class="menu-text">{{ item.name }}</h4>
          <i class="menu-icon flaticon-more-v2"></i>
        </li>
      </template>

      <template v-else>
        <router-link :to="item.url" v-slot="{ href, navigate, isActive, isExactActive }" :key="index">
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i :class="'menu-icon ' + item.icon"></i>
              <span class="menu-text">{{ item.name }}</span>
            </a>
          </li>
        </router-link>
      </template>

      <template v-if="item.menu">
        <template v-for="menu in item.menu">
          <router-link
            v-if="!menu.children"
            :key="menu.url"
            :to="menu.url"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i :class="'menu-icon ' + menu.icon"></i>
                <span class="menu-text">{{ menu.name }}</span>
              </a>
            </li>
          </router-link>

          <li
            v-if="menu.children"
            :key="menu.url"
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item menu-item-submenu"
            v-bind:class="{
              'menu-item-active': hasActiveChildren(menu.url)
            }"
          >
            <a href="#" class="menu-link menu-toggle">
              <i :class="'menu-icon ' + menu.icon"></i>
              <span class="menu-text"> {{ menu.name }} </span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu menu-submenu-classic menu-submenu-right">
              <ul class="menu-subnav">
                <router-link
                  v-for="children in menu.children"
                  :key="children.url"
                  :to="children.url"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text"> {{ children.name }} </span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>
          </li>
        </template>
      </template>
    </template>
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  props: {
    menuItems: { type: Array, required: true }
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
