export default [
  {
    name: "Dashboard",
    icon: "fas fa-chart-line",
    url: "/dashboard"
  },
  {
    header: true,
    name: "Client",
    icon: "flaticon2-architecture-and-city",
    menu: [
      {
        url: "/client/job/listing",
        name: "Job",
        icon: "flaticon-clipboard"
      },
      {
        url: "/client/job/create",
        name: "Create Job",
        icon: "flaticon-business"
      },
      {
        url: "/client/job/batch",
        name: "Batch Upload Job",
        icon: "fas fa-file-upload"
      },
      {
        url: "/client/job/search",
        name: "Search",
        icon: "la la-search"
      },
      {
        url: "/client/job/track",
        name: "Track",
        icon: "flaticon2-layers-1"
      },
      {
        url: "/client/address-book",
        name: "Address Book",
        icon: "far fa-building"
      }
    ]
  }
];
