<template>
  <div>
    <div
      class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <h4 class="d-flex flex-center rounded-top">
        <span class="text-white">User Notifications</span>
        <span class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
          {{ notificationCount }} new
        </span>
        <span class="btn btn-text btn-warning btn-sm font-weight-bold btn-font-md ml-2" @click="clearNotification">
          Clear
        </span>
      </h4>

      <ul
        class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
        role="tablist"
      >
        <li class="nav-item">
          <a v-on:click="setActiveTab" data-tab="0" class="nav-link active" data-toggle="tab" href="#" role="tab">
            Alerts
          </a>
        </li>
      </ul>
    </div>

    <b-tabs class="hide-tabs" v-model="tabIndex">
      <b-tab active class="p-8">
        <perfect-scrollbar class="scroll pr-7 mr-n7" style="max-height: 40vh; position: relative;">
          <template v-for="(item, i) in notifications">
            <!--begin::Item-->
            <div class="d-flex align-items-center mb-6" v-bind:key="i">
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <a href="#" class="text-dark text-hover-primary mb-1 font-size-lg">
                  {{ item.title }}
                </a>
                <span class="text-muted">
                  {{ item.body }}
                </span>
                <span class="text small text-right">
                  {{ item.ago }}
                </span>
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->
          </template>
        </perfect-scrollbar>
      </b-tab>
    </b-tabs>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import { mapGetters } from "vuex";
export default {
  name: "KTDropdownNotification",
  data() {
    return {
      tabIndex: 0
    };
  },
  methods: {
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },

    clearNotification() {
      this.$store.dispatch("clearNotifications");
    }
  },
  computed: {
    ...mapGetters(["notifications", "notificationCount"]),
    backgroundImage() {
      return process.env.BASE_URL + "media/misc/bg-1.jpg";
    }
  }
};
</script>
