export default [
  {
    name: "Dashboard",
    icon: "fas fa-chart-line",
    url: "/dashboard",
  },
  {
    header: true,
    name: "Jobs",
    icon: "flaticon2-architecture-and-city",
    menu: [
      {
        url: "/job/listing",
        name: "Job",
        icon: "flaticon-clipboard",
      },
      {
        url: "/job/create",
        name: "Create Job",
        icon: "flaticon-business",
      },
      {
        url: "/job/batch",
        name: "Batch Upload Job",
        icon: "fas fa-file-upload",
      },
      {
        url: "/job/dispatch",
        name: "Dispatch",
        icon: "flaticon-list-3",
      },
      {
        url: "/job/search",
        name: "Search",
        icon: "la la-search",
      },
      {
        url: "/job/track",
        name: "Track",
        icon: "flaticon2-layers-1",
      },
      {
        url: "/job/optimize/listing",
        name: "Optimize",
        icon: "flaticon2-map",
      },
      {
        url: "/map",
        name: "Map",
        icon: "flaticon2-map",
      },
    ],
  },
  {
    header: true,
    name: "Warehouse",
    icon: "flaticon2-architecture-and-city",
    menu: [
      {
        url: "/warehouse/dashboard",
        name: "Dashboard",
        icon: "flaticon-buildings",
      },
      {
        url: "/warehouse/listing",
        name: "Overview",
        icon: "flaticon-buildings",
      },
      {
        url: "/warehouse/inbound",
        name: "Inbound",
        icon: "flaticon2-cube",
      },
      {
        url: "/warehouse/outbound",
        name: "Outbound",
        icon: "flaticon2-delivery-package",
      },
    ],
  },
  {
    header: true,
    name: "Report",
    icon: "flaticon2-architecture-and-city",
    menu: [
      {
        url: "/report/job",
        name: "Job Report",
        icon: "la la-search",
      },
    ],
  },
];
