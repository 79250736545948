var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_vm._l((_vm.menuItems),function(item,index){return [(item.header)?[_c('li',{key:index,staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(item.name))]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])]:[_c('router-link',{key:index,attrs:{"to":item.url},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{class:'menu-icon ' + item.icon}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(item.name))])])])]}}],null,true)})],(item.menu)?[_vm._l((item.menu),function(menu){return [(!menu.children)?_c('router-link',{key:menu.url,attrs:{"to":menu.url},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{class:'menu-icon ' + menu.icon}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(menu.name))])])])]}}],null,true)}):_vm._e(),(menu.children)?_c('li',{key:menu.url,staticClass:"menu-item menu-item-submenu",class:{
            'menu-item-active': _vm.hasActiveChildren(menu.url)
          },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{class:'menu-icon ' + menu.icon}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(menu.name)+" ")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},_vm._l((menu.children),function(children){return _c('router-link',{key:children.url,attrs:{"to":children.url},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(children.name)+" ")])])])]}}],null,true)})}),1)])]):_vm._e()]})]:_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }