export default [
  {
    header: true,
    name: "Admin",
    icon: "flaticon2-architecture-and-city",
    menu: [
      {
        url: "/client-company/listing",
        name: "Client",
        icon: "far fa-building"
      },
      {
        url: "/driver/listing",
        name: "Driver",
        icon: "far fa-user-circle"
      },
      {
        url: "/users/listing",
        name: "User",
        icon: "la la-user-tie"
      },
      {
        url: "/admin/address-book",
        name: "Address Book",
        icon: "la la-address-book-o"
      }
    ]
  }
];
