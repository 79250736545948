<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin: PrintList -->
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
      v-show="printCount > 0"
    >
      <template v-slot:button-content>
        <span class="label label-warning label-lg font-weight-bold label-rounded pulse mr-5">
          <span class="position-relative flaticon2-printer"></span>
        </span>
        <!-- <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary">
          <span class="svg-icon svg-icon-xl svg-icon-danger">
            <inline-svg src="/media/svg/icons/Code/Compiling.svg" />
          </span>
          <span class="pulse-ring"></span>
        </div> -->
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <form>
          <DropdownPrintList></DropdownPrintList>
        </form>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: PrintList -->
    <!--begin: Notifications -->
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
      v-show="notificationCount > 0"
    >
      <template v-slot:button-content>
        <span class="label label-primary label-lg font-weight-bold label-rounded pulse mr-3">
          <span class="position-relative">{{ notificationCount }}</span>
          <span class="pulse-ring"></span>
        </span>
        <!-- <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary">
          <span class="svg-icon svg-icon-xl svg-icon-danger">
            <inline-svg src="/media/svg/icons/Code/Compiling.svg" />
          </span>
          <span class="pulse-ring"></span>
        </div> -->
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <form>
          <KTDropdownNotification></KTDropdownNotification>
        </form>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Notifications -->

    <!--begin: User Bar -->
    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
import DropdownPrintList from "@/view/layout/extras/dropdown/DropdownPrintList.vue";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
import i18nService from "@/core/services/i18n.service.js";

import { mapGetters } from "vuex";

export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages
    };
  },
  components: {
    KTDropdownNotification,
    DropdownPrintList,
    KTQuickUser
  },
  mounted() {
    this.$root.$on("bv::dropdown::show", () => {
      this.$store.dispatch("updateTime");
    });
  },
  computed: {
    ...mapGetters(["notificationCount", "printCount"])
  }
};
</script>
